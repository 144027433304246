<template>
  <ui-transition>
    <div v-if="!isLoading">
      <router-link
        v-for="property in properties"
        :key="property.id"
        :to="`/properties/${property.name}`"
        class="bg-level-1 rounded-xl shadow-xl px-4 py-3 mb-1 active:bg-level-3 flex items-center"
      >
        <div class="flex-grow mr-4">
          <h2 class="text-main">
            {{ property.title }}
          </h2>
        </div>
        <div class="flex-shrink-0">
          <font-awesome-icon
            class="text-sub text-lg"
            :icon="['fas', 'chevron-right']"
          />
        </div>
      </router-link>
    </div>
  </ui-transition>
</template>

<script>
export default {
  name: 'PropertySelection',
  data: () => ({
    isLoading: true,
    properties: [
      { name: 'equipments-places', title: '備品の置き場所' },
      { name: 'equipments-categories', title: '備品タグ' },
    ],
  }),
  mounted() {
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },
};
</script>
